// Range slider
$range-thumb-size: 36px;

input[type=range] {
    appearance: none;
    background: rgb(161, 100, 100);
    border-radius: 3px;
    height: 6px;
    margin-top: ($range-thumb-size - 6px) / 2;
    margin-bottom: ($range-thumb-size - 6px) / 2;
    --thumb-size: #{$range-thumb-size};  

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background-color: #000;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        height: $range-thumb-size;
        width: $range-thumb-size;            
    }

    &::-moz-range-thumb {
        background-color: #000;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: $range-thumb-size;
        width: $range-thumb-size;
    }
    
    &::-ms-thumb {
        background-color: #000;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        height: $range-thumb-size;
        width: $range-thumb-size;
    }
    
    &::-moz-focus-outer {
        border: 0;
    }
}